import React from 'react';
import Header from '../../layout/header/Header';
import RegisterCallList from '../../components/registerCallList/RegisterCallList';
import { Grid, Box } from '@mui/material';

const RegisteredCallListPage = () => {
  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Box>
          <Header />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <RegisterCallList />
        </Box>
      </Grid>
    </Grid>
  );
};

export default RegisteredCallListPage;