import https from './Https';

const login = (data) => {
    return https.post('/login/proceed', data)
}
const caseList = (data) => {
    return https.post('/mobile/cases_test', data)
}
const allServceCallList = (data) => {
    return https.post('/mobile/all_service_lists', data)
}
const refurbishmentList = (data) => {
    return https.post('/mobile/all_refurbishment_lists', data)
}
const dashboard = (data) => {
    return https.post('/mobile/dashboard', data)
}
const getallstates = () => {
    return https.get('/mobile/getallstates')
}
const getCity = (data) => {
    return https.post('/mobile/getlocation', data)
}
const getCityByCityId = (data) => {
    return https.post('/mobile/getcityname_byid', data)
}
const getSubCity = (data) => {
    return https.post('/mobile/getsublocation', data)
}
const getallmodels = () => {
    return https.get('/mobile/getallmodels')
}
const getallServiceProvider = () => {
    return https.get('/mobile/get_all_service_provider')
}
const getServiceProviderById = (data) => {
    return https.post('/mobile/get_all_service_provider_by_id', data)
}
const getCustomerById = (data) => {
    return https.post('/mobile/customer', data)
}
const getDefectcatg = () => {
    return https.get('/mobile/defectcatg')
}
const getparts = () => {
    return https.post('/mobile/getparts')
}
const addNewCall = (data) => {
    return https.post('/mobile/new_case',data)
}
const getActualDefect = (data) => {
    return https.post('/common/get_actual_defauct',data)
}
const updateCall = (data) => {
    return https.post('/mobile/update_case_test',data)
}
const gethappycode = (data) => {
    return https.post('/mobile/gethappycode/',data)
}
const getCasesUsedParts = (data) => {
    return https.post('/mobile/cases_used_part/',data)
}
const getCasesImages = (data) => {
    return https.post('/mobile/cases_images/',data)
}
const ConfigServe = {
    login,
    caseList,
    allServceCallList,
    refurbishmentList,
    dashboard,
    getallstates,
    getCity,
    getCityByCityId,
    getSubCity,
    getallmodels,
    getallServiceProvider,
    getServiceProviderById,
    getCustomerById,
    getDefectcatg,
    getparts,
    addNewCall,
    getActualDefect,
    updateCall,
    gethappycode,
    getCasesUsedParts,
    getCasesImages,
}

export default ConfigServe;