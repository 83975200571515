import React from 'react';
import { Card, CardContent, Grid, TextField, Button, Select, MenuItem, InputLabel, FormControl, Stack, Box } from '@mui/material';

const RegisterCallListSearch = ({
  fromDate,
  toDate,
  overallSearch,
  selectedOption,
  handleFromDate,
  handleToDate,
  handleOverallSearch,
  handleOption,
  handleSearch,
  optionData
}) => {
  return (
    <Box mb={2}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Overall Search"
                name='overallSearch'
                id='overallSearch'
                fullWidth
                value={overallSearch || ""}
                onChange={handleOverallSearch}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction="row" spacing={1}>
                <TextField
                  label="From Date"
                  type="date"
                  value={fromDate}
                  onChange={handleFromDate}
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: '41vw' }}
                />
                <TextField
                  label="To Date"
                  type="date"
                  value={toDate}
                  onChange={handleToDate}
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: '41vw' }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              {/* <FormControl fullWidth>
                <InputLabel>Select Option</InputLabel>
                <Select value={selectedOption} onChange={handleOption}>
                  {optionData.map((option) => (
                    <MenuItem key={option.id} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              <TextField
                select
                fullWidth
                name="status"
                value={selectedOption}
                onChange={handleOption}
                margin="normal"
              >
                {optionData.map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" onClick={handleSearch} fullWidth>
                Search
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default RegisterCallListSearch;
