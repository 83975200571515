import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './redux/rootReducer';
import rootSaga from './redux/rootSaga';

const sagaMiddleware = createSagaMiddleware();
let middleware = [sagaMiddleware]; // Wrap sagaMiddleware in an array

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const getMiddleware = () => {
  const middleware = [sagaMiddleware];
  return middleware;
};

const store = configureStore({
  reducer: persistedReducer,
  middleware: getMiddleware,
});


const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };