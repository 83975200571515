import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DashboardCards from './DashboardCards';
import ConfigServe from '../../middleware/ConfigServe';

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState([]);

  const fetchDashboardData = async () => {
    const user_id = localStorage.getItem("user_id");
    const designation_id = localStorage.getItem("designation_id");
    const user_type = localStorage.getItem("user_type");
    const cust_id = localStorage.getItem("cust_id");
    try {
      let dashboardData = new FormData();
      dashboardData.append("user_id", user_id);
      dashboardData.append("designation_id", designation_id);
      dashboardData.append("user_type", user_type);
      dashboardData.append("cust_id", cust_id);
      dashboardData.append("sdata", '');
      dashboardData.append("sdate", '');
      dashboardData.append("edate", '');
      const res = await ConfigServe.dashboard(dashboardData);
      if (res.response.status === 200) {
        setDashboardData(res.all_calls);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  return (
    <Box sx={{ backgroundColor: '#bef2ff' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box mt={2} mx={1}>
            <Typography variant="h6" align="center" gutterBottom>
              Dashboard
            </Typography>
            <DashboardCards dashboardData={dashboardData} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mx={1}>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
