import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const libraries = ['marker'];

const ViewLocation = ({ apiKey, state, city, outletName, open, onClose }) => {
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [currentLocation, setCurrentLocations] = useState({ lat: 0, lng: 0 });
    const [error, setError] = useState('');
    const [marker, setMarker] = useState(null);
    const mapStyles = {
      height: "400px",
      width: "100%"
    };

    useEffect(() => {
        // Request user's location when component mounts
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCurrentLocations({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                    setError('');
                },
                (error) => {
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            setError('User denied the request for Geolocation.');
                            break;
                        case error.POSITION_UNAVAILABLE:
                            setError('Location information is unavailable.');
                            break;
                        case error.TIMEOUT:
                            setError('The request to get user location timed out.');
                            break;
                        default:
                            setError('An unknown error occurred.');
                            break;
                    }
                }
            );
        } else {
            setError('Geolocation is not supported by this browser.');
        }
    }, []);
    const { isLoaded, loadError } = useJsApiLoader({
      googleMapsApiKey: apiKey,
      libraries: libraries
    });

    const cleanOutletName = (name) => {
        return name?.replace(/[^a-zA-Z0-9\s,]/g, '').replace(/[\s,]+/g, '+');
    };
    

    const geocodeAddress = useCallback(() => {
      if (!isLoaded) return;

      const cleanedOutletName = cleanOutletName(outletName);
      const address = `${cleanedOutletName}${city}+${state}`;
      const geocoder = new window.google.maps.Geocoder();
      
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === 'OK') {
          const newCenter = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          };
          setCenter(newCenter);

          // Create the AdvancedMarkerElement
          const { AdvancedMarkerElement } = window.google.maps.marker;
          const newMarker = new AdvancedMarkerElement({
            map: null, // We'll add it to the map later
            position: newCenter,
            title: cleanedOutletName
          });
          setMarker(newMarker);
        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
        }
      });
    }, [isLoaded, outletName, city, state]);

    useEffect(() => {
      if (isLoaded) {
        geocodeAddress();
      }
    }, [isLoaded, geocodeAddress]);

    const onMapLoad = useCallback((map) => {
      if (marker) {
        marker.map = map;
      }
    }, [marker]);

    if (loadError) {
      return <div>Map cannot be loaded right now, sorry.</div>
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                View Location
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {isLoaded ? (
                    <GoogleMap
                        mapContainerStyle={mapStyles}
                        zoom={13}
                        center={center}
                        onLoad={onMapLoad}
                    >
                        {/* The AdvancedMarkerElement is added to the map in the onMapLoad callback */}
                    </GoogleMap>
                ) : (
                    <div>Loading...</div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ViewLocation;
