import React, { useState } from 'react';
import {
  styled,
  Card,
  CardContent,
  Typography,
  TextField,
  MenuItem,
  Button,
  Grid,
  Stack,
} from '@mui/material';

// const CustomCard = styled(Card)`
//   max-width: 400px;
//   margin: 10px auto;
//   padding: 6px;
//   @media (max-width: 600px) {
//     margin: 10px;
//   }
// `;

// const SearchButton = styled(Button)`
//   background-color: #003366;
//   color: white;
//   &:hover {
//     background-color: #002244;
//   }
// `;

const PMCallsSearch = ({ 
  fromDate,
  toDate,
  overallSearch,
  selectedOption,
  handleFromDate,
  handleToDate,
  handleOverallSearch,
  handleOption,
   onSearch ,
   optionData,
  }) => {

  // const handleSearchTypeChange = (event) => {
  //   setSearchType(event.target.value);
  // };

  // const handleOptionChange = (event) => {
  //   setOption(event.target.value);
  // };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Overall Search"
              value={overallSearch || ""}
              onChange={handleOverallSearch}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction="row" spacing={1}>
              <TextField
                label="From Date"
                type="date"
                value={fromDate}
                onChange={handleFromDate}
                InputLabelProps={{ shrink: true }}
                sx={{ width: '41vw' }}
              />
              <TextField
                label="To Date"
                type="date"
                value={toDate}
                onChange={handleToDate}
                InputLabelProps={{ shrink: true }}
                sx={{ width: '41vw' }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              value={selectedOption}
              onChange={handleOption}
              margin="normal"
            >
              {optionData.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth variant="contained" onClick={onSearch}>
              Search
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PMCallsSearch;
