import React, { useState } from 'react';
import { Box, Grid, TextField, Button, Checkbox, FormControlLabel, Typography, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ConfigServe from '../../middleware/ConfigServe';

const Login = () => {
  const navigate = useNavigate();
  const initialFormData = {
    username: '',
    password: ''
  }
  const [formData, setFormData] = useState(initialFormData);
  const [rememberMe, setRememberMe] = useState(false);
  const initialValidationError = {
    username: '', 
    password: '' 
 }
  const [validationError, setValidationError] = useState(initialValidationError); 
  const [alertMessage, setAlertMessage] = useState(""); 
  
  const handleInput = (params) => {
    try{
      const { name, value } = params.target;
      setFormData((prev)=>({
        ...prev,
        [name]: value
      }));

      if(validationError[name]){
        setValidationError((prev)=>({
          ...prev,
          [name]: ''
        }));
      }
    }
    catch(e){
      console.log(e);
    }
  }
  
  const handleLogin = async (e) => {
    e.preventDefault();
    const isValid = await validateForm();
    if(isValid === false){
      return false;
    }
    try {
      let loginData = new FormData();
      loginData.append("username", formData.username);
      loginData.append("password", formData.password);
      const res = await ConfigServe.login(loginData);
      if (res.response.status === 200) {
        navigate("/register-call-List");
        localStorage.setItem("Token",res.response.token)
        localStorage.setItem("cust_id",res.response.cust_id)
        localStorage.setItem("designation_id",res.response.designation_id)
        localStorage.setItem("user_id",res.response.user_id)
        localStorage.setItem("user_type",res.response.user_type)
      } 
      else {
        setAlertMessage(res.response.message); 
      }
    } 
    catch (error) {
      console.error(error);
    }
  };

  const validateForm = async() => {
    let errors = {};
    if (!formData.username) {
      errors.username = 'User ID is required';
    }
    if (!formData.password) {
      errors.password = 'Password is required';
    }
    setValidationError(errors);
    return Object.values(errors).every((error)=> error === '');
  };

  return (
    <Box sx={{ background: "linear-gradient(109.6deg, rgb(204, 228, 247) 11.2%, rgb(237, 246, 250) 100.2%)" }}>
      <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: { xs: '100vh', md: '100vh' } }}>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Box bgcolor="white" p={4} borderRadius={4} boxShadow={5} margin={2}>
            <Box textAlign="center" mb={4}>
              {/* <img src={logoNew} alt="Logo" style={{ maxWidth: '200px' }} /> */}
            </Box>
            <Typography variant='h6' component="h2" gutterBottom>
              SIGN IN
            </Typography>
            {alertMessage && (
              <Alert severity="error">{alertMessage}</Alert>
            )}
            <form onSubmit={handleLogin}>
              <TextField
                label="User ID"
                name="username"
                type='text'
                value={formData.username}
                onChange={handleInput}
                fullWidth
                margin="normal"
                style={{ backgroundColor: 'white', borderRadius: 4 }}
                error={!!validationError.username}
                helperText={validationError.username}
              />
              <TextField
                label="Password"
                name='password'
                type="password"
                value={formData.password}
                onChange={handleInput}
                fullWidth
                margin="normal"
                style={{ backgroundColor: 'white', borderRadius: 4 }}
                error={!!validationError.password}
                helperText={validationError.password}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    color="primary"
                    style={{ color: 'inherit' }}
                  />
                }
                label="Remember me"
                style={{ color: 'inherit' }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                style={{
                  fontWeight: 'bold',
                  marginTop: '1rem',
                  backgroundColor: "#1acdee",
                }}
              >
                SIGN IN
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;