import { Box, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import NewRegisterCallForm from './NewRegisterCallForm'
import UploadPhoto from '../registerCallList/UploadPhoto'

const NewCallRegister = () => {
    const selectCallType = [
        { id: 0, label: "Preventive Maintenance", value: "Preventive Maintenance" },
        { id: 1, label: "Refurbishment", value: "Refurbishment" },
        { id: 2, label: "121 Installation", value: "121 Installation" },
       ];
    const callStatus = [
        { id: 1, label: "Register", value: "Register" },
        { id: 2, label: "Technical Closer", value: "Technical Closer" },
        { id: 3, label: "Open", value: "Open" },
    ];
    const warrantyStatus = [
        { id: 1, label: "Please Select Warranty Status ", value: "" },
        { id: 2, label: "AMC", value: "AMC" },
        { id: 3, label: "IW", value: "IW" },
        { id: 4, label: "OW", value: "OW" },
        { id: 5, label: "LMC", value: "LMC" },
    ];
  const labels = [
    "Shop Name / Sign Board",
    "Model, Serial & Asset",
    "Cooler front side",
    "Owner Photo/Signature",
    "Defective Part"
];
const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
const [filePreviews, setFilePreviews] = useState(labels.map(() => []));

  // **Define functions to manage uploadPhoto dialog **//
  const handleUploadDialogOpen = () => {
    setUploadDialogOpen(true);
};

const handleUploadDialogClose = () => {
    setUploadDialogOpen(false);
};

const handleFileChange = (event, index) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
        const newFilePreviews = [...filePreviews];
        files.forEach(file => {
            const reader = new FileReader();
            reader.onloadend = () => {
                newFilePreviews[index].push(reader.result);
                setFilePreviews([...newFilePreviews]);
            };
            reader.readAsDataURL(file);
        });
    }
};
const removePreview = (labelIndex, previewIndex) => {
    const newFilePreviews = [...filePreviews];
    newFilePreviews[labelIndex].splice(previewIndex, 1);
    setFilePreviews(newFilePreviews);
};

const handleUpload = () => {
    // Implement upload logic here
};
  return (
    <>
<Box sx={{backgroundColor:'#bef2ff'}}>
        <Grid container spacing={2} >
            <Grid item xs={12}>
                <Box mt={2} mx={1}>
                    <Typography variant="h6" align="center" gutterBottom>
                    Register New Call
                    </Typography>
                    <NewRegisterCallForm
                       handleUploadDialogOpen={handleUploadDialogOpen}
                       selectCallType={selectCallType}
                       callStatus={callStatus}
                       warrantyStatus={warrantyStatus}
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box mx={1}>
                </Box>
            </Grid>
        </Grid>
        {/* <UploadPhoto
                open={uploadDialogOpen}
                handleClose={handleUploadDialogClose}
                labels={labels}
                handleFileChange={handleFileChange}
                handleUpload={handleUpload}
                removePreview={removePreview}
                filePreviews={filePreviews}
            /> */}
        </Box>
    </>
  )
}

export default NewCallRegister