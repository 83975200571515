// actionTypes.js
export const LOGIN_ACTIONS={
LOGIN_REQUEST : 'LOGIN_REQUEST',
LOGIN_SUCCESS : 'LOGIN_SUCCESS',
LOGIN_FAILURE : 'LOGIN_FAILURE',
LOGOUT : 'LOGOUT',
}
// actionCreators.js
export const loginRequestAction = (payload) => ({
  type: LOGIN_ACTIONS.LOGIN_REQUEST,
  ...payload,
});

export const loginSuccessAction = (payload) => ({
  type: LOGIN_ACTIONS.LOGIN_SUCCESS,
  ...payload,
});

export const loginFailureAction = (payload) => ({
  type: LOGIN_ACTIONS.LOGIN_FAILURE,
  ...payload,
});

export const logoutAction = () => ({
  type: LOGIN_ACTIONS.LOGOUT,
});

export default{
  LOGIN_ACTIONS,
  loginRequestAction,
  loginSuccessAction,
  loginFailureAction,
  logoutAction
}