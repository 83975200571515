import React from 'react'
import Header from '../../layout/header/Header'
import { Grid, Box } from '@mui/material';
import PMCalls from '../../components/pmCalls/PMCalls';
// import PMCallsSearch from '../../components/pmCalls/PMCallsSearch';
// import PMCallsGridList from '../../components/pmCalls/PMCallsGridList';

const PMCallsPage = () => {
  return (
    <>
     <Grid container spacing={6}>
      <Grid item xs={12}>
        <Box>
          <Header />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
            <PMCalls/>
        </Box>
      </Grid>
      {/* <Grid item xs={12}>
        <Box>
            <PMCallsSearch/>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
            <PMCallsGridList/>
        </Box>
      </Grid> */}
    </Grid>
    </>
  )
}

export default PMCallsPage