import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../../assets/styleSheet/style.css'; // Importing the CSS file for additional styling

const ViewImageList = ({ open, handleClose, images }) => {
  const hasImages = images && images.length > 0;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        Image Carousel
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {hasImages ? (
          <Carousel
            showArrows={true}
            showThumbs={false}
            dynamicHeight={true}
            autoPlay={false}
            infiniteLoop={true}
          >
            {images.map((imageData, index) => (
              <div className="carousel-slide" key={index}>
                <img
                  src={imageData.image}
                  alt={`Slide ${index}`}
                  className="carousel-image" // Apply dynamic image size class
                />
                <div className="image-overlay">
                  <Typography variant="body2">
                    <strong>Latitude:</strong> {imageData.latitude}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Longitude:</strong> {imageData.longitude}
                  </Typography>
                  {/* <Typography variant="body2">
                    <strong>Reference No:</strong> {imageData.ref_unique_id}
                  </Typography> */}
                  <Typography variant="body2">
                    <strong>Outlet Name:</strong> {imageData.outlet_name}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Serial No:</strong> {imageData.serial_no}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Address:</strong> {imageData.address}
                  </Typography>
                </div>
              </div>
            ))}
          </Carousel> 
        ) : (
          <Typography>No images to display.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewImageList;
