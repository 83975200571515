// sagas/authSaga.js
import { call, put, takeLatest } from 'redux-saga/effects';
import { LOGIN_ACTIONS,loginSuccessAction,loginFailureAction } from '../actions/authActions';
import ConfigServe from '../../middleware/ConfigServe'; // Replace with your actual service

function* loginSaga(action) {
  try {
    const user = yield call(ConfigServe.login, action.payload);
    yield put(loginSuccessAction(user));
  } catch (error) {
    yield put(loginFailureAction(error.message));
  }
}

export default function* authSaga() {
  yield takeLatest(LOGIN_ACTIONS.LOGIN_REQUEST, loginSaga);
}