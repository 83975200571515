import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, CircularProgress, Alert } from '@mui/material';
import RegisterCallListSearch from './RegisterCallListSearch';
import RegisterCallListGrid from './RegisterCallListGrid';
import UpdateRegistration from './UpdateRegistration';
import UploadPhoto from './UploadPhoto';
import ConfigServe from '../../middleware/ConfigServe';

const RegisterCallList = () => {
    
    const optionData = [
        { id: 0, label: "All", value: "all" },
        { id: 1, label: "Register", value: "Register" },
        { id: 2, label: "Pending", value: "Pending" },
        { id: 3, label: "Reopen", value: "Reopen" },
    ];
    
    const selectCallType = [
        { id: 1, label: "Scheduled", value: "Scheduled" },
        { id: 2, label: "Pending", value: "Pending" },
        { id: 3, label: "Technical Closer", value: "Technical Closer" },
    ];

    const subtitles = [
        "Serial Number:",
        "Outlet Name:",
        "Address:",
        "Phone No:",
        "Call Type:",
        "Status:",
    ];

    const dataKeys = [
        "serial_number",
        "outlet_id",
        "address_1",
        "phone_no",
        "call_type",
        "call_status",
    ];

    const labels = [
        "Shop Name / Sign Board",
        "Model, Serial & Asset",
        "Cooler front side",
        "Owner Photo/Signature",
        "Defective Part"
    ];
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [selectedOption, setSelectedOption] = useState(optionData[0].value);
    const [overallSearch, setOverallSearch] = useState('');
    const [fetchedData, setFetchedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedCall, setSelectedCall] = useState(null);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [filePreviews, setFilePreviews] = useState(labels.map(() => []));
    
    //** API call for RegiterCallList**//
    const fetchCallList = async () => {
        const user_id = localStorage.getItem("user_id");
        const designation_id = localStorage.getItem("designation_id");
        const user_type = localStorage.getItem("user_type");
        const cust_id = localStorage.getItem("cust_id");

        try {
            let callListData = new FormData();
            callListData.append("user_id", user_id);
            callListData.append("designation_id", designation_id);
            callListData.append("user_type", user_type);
            callListData.append("cust_id", cust_id);
            callListData.append("sdata", overallSearch);
            callListData.append("sdate", fromDate);
            callListData.append("edate", toDate);
            callListData.append("status", selectedOption);
            setLoading(true);
            const res = await ConfigServe.caseList(callListData);
            if (res.response.status === 200) {
                setFetchedData(res.cases);
                setAlertMessage("");
            } else {
                setFetchedData([]);
                setAlertMessage("No file matched. Please check the search term");
            }
        } catch (error) {
            console.error(error);
            setAlertMessage("An error occurred while fetching the data.");
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchCallList();
    }, []);

    //**  Functions to handle RegisterCallListSearch **//
    const handleOverallSearch = (e) => {
        setOverallSearch(e.target.value)
    }

    const handleFromDate = (e) => {
        setFromDate(e.target.value)
    }

    const handleToDate = (e) => {
        setToDate(e.target.value);
    };

    const handleOption = (e) => {
        setSelectedOption(e.target.value);
    };


    const handleSearch = () => {
        fetchCallList();
    };

    //** Function to handle opening the dialog UpdateRegistration and passing selected call data **//
    const handleUpdate = (data) => {
        setSelectedCall(data);
        setDialogOpen(true);
    };
    const handleViewLocation= () => {

    }
    
    // Function to handle closing the dialog
    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    // Function to handle saving updated data
    const handleSaveUpdatedData = (updatedData) => {
        // Logic to save the updated data
        console.log("Updated Call Data:", updatedData);
        // Close the dialog after saving
        setDialogOpen(false);
        // Optionally, you may want to refresh the call list
        // fetchCallList();
    };

    // **Define functions to manage uploadPhoto dialog **//
    const handleUploadDialogOpen = () => {
        setUploadDialogOpen(true);
    };

    const handleUploadDialogClose = () => {
        setUploadDialogOpen(false);
    };

    const handleFileChange = (event, index) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            const newFilePreviews = [...filePreviews];
            files.forEach(file => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    newFilePreviews[index].push(reader.result);
                    setFilePreviews([...newFilePreviews]);
                };
                reader.readAsDataURL(file);
            });
        }
    };
    const removePreview = (labelIndex, previewIndex) => {
        const newFilePreviews = [...filePreviews];
        newFilePreviews[labelIndex].splice(previewIndex, 1);
        setFilePreviews(newFilePreviews);
    };

    const handleUpload = () => {
        // Implement upload logic here
    };
    return (
        <Box sx={{ backgroundColor: '#bef2ff' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box mt={2} mx={1}>
                        <Typography variant="h6" align="center" gutterBottom>
                            Register Call List
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box mx={1}>
                        <RegisterCallListSearch
                            fromDate={fromDate}
                            toDate={toDate}
                            overallSearch={overallSearch}
                            selectedOption={selectedOption}
                            handleFromDate={handleFromDate}
                            handleToDate={handleToDate}
                            handleOverallSearch={handleOverallSearch}
                            handleOption={handleOption}
                            handleSearch={handleSearch}
                            optionData={optionData}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box mx={1}>
                        {loading ? (
                            <Box display="flex" justifyContent="center" alignItems="center" mt={5} height="200px">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                {alertMessage && (
                                    <Alert severity="warning">{alertMessage}</Alert>
                                )}
                                <RegisterCallListGrid
                                    fetchedData={fetchedData}
                                    subtitles={subtitles}
                                    dataKeys={dataKeys}
                                    handleUpdate={handleUpdate}
                                    handleViewLocation={handleViewLocation}
                                />
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>
            {/* UpdateRegistration component */}
            {selectedCall &&
            <UpdateRegistration
                open={dialogOpen}
                handleClose={handleCloseDialog}
                formData={selectedCall}
                // handleSave={handleSaveUpdatedData}
                handleUploadDialogOpen={handleUploadDialogOpen}
                selectCallType={selectCallType}
            />}
            {/* UploadPhoto component */}
            {/* <UploadPhoto
                open={uploadDialogOpen}
                handleClose={handleUploadDialogClose}
                // labels={labels}
                // handleFileChange={handleFileChange}
                handleUpload={handleUpload}
                // removePreview={removePreview}
                // filePreviews={filePreviews}
            /> */}
        </Box>
    );
};

export default RegisterCallList;
