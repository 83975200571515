import React from 'react'
import RefurbishmentCalls from '../../components/refurbishmentCalls/RefurbishmentCalls'
import { Box, Grid } from '@mui/material'
import Header from '../../layout/header/Header'

const RefurbishmentCallsPage = () => {
  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Box>
          <Header />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <RefurbishmentCalls />
        </Box>
      </Grid>
    </Grid>
  )
}

export default RefurbishmentCallsPage