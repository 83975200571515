import React from 'react'
import { Grid, Box } from '@mui/material';
import Header from '../../layout/header/Header'
import Dashboard from '../../components/dashboard/Dashboard'

const DashboardPage = () => {
  return (
    <>
     <Grid container spacing={6}>
      <Grid item xs={12}>
        <Box>
          <Header />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Dashboard/>
        </Box>
      </Grid>
    </Grid>
    </>
  )
}

export default DashboardPage
