import React, { useEffect, useState } from 'react';
import { Card, CardContent, Button, Grid, TextField, Typography, MenuItem } from '@mui/material';
import { fetchState, fetchCity, fetchModel, fetchServiceProvider, fetchSubCity, fetchCustomerById } from '../../utils/CommonFunction'
import ConfigServe from '../../middleware/ConfigServe';
import UploadPhoto from '../registerCallList/UploadPhoto';

const NewRegisterCallForm = ({
  // handleUploadDialogOpen,
  selectCallType,
  warrantyStatus,
  callStatus,
}) => {
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const [newRegData, setNewRegData] = useState({
    call_type: 'Preventive Maintenance',
    call_date: getCurrentDateTime(),
    referenceId: '',
    assetTag: '',
    serial_number: '',
    customer_name: '',
    outlet_name: '',
    address: '',
    state: '',
    city: '',
    subCity: '',
    contact_person: '',
    phone_no: '',
    inv_date: '',
    status: '',
    model: '',
    service_provider: '',
    appointment_date: getCurrentDateTime(),
    call_status: 'Technical Closer',
    tech_remark: '',
    // photo: null,
  });

  useEffect(() => {
    if (newRegData.call_type === 'Refurbishment') {
      setNewRegData(prevState => ({
        ...prevState,
        call_status: 'Open'
      }));
    } else if (newRegData.call_type === 'Preventive Maintenance') {
      setNewRegData(prevState => ({
        ...prevState,
        call_status: 'Technical Closer'
      }));
    }
  }, [newRegData.call_type])

  const [errors, setErrors] = useState({});
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [subcities, setSubCities] = useState([]);
  const [modal, setModals] = useState([]);
  const [serviceProvider, setServiceProvider] = useState([]);
  const [custmoer, setCustomer] = useState([]);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [pic, setPic] = useState([]);

  // **Define functions to manage uploadPhoto dialog **//
  const handleUploadDialogOpen = () => {
    setUploadDialogOpen(true);
  };

  const handleUploadDialogClose = () => {
    setUploadDialogOpen(false);
  };

  const handleUpload = (filePreviews) => {
    // Implement upload logic here
    console.log('Files to upload:', filePreviews);
    setPic(filePreviews)
    handleUploadDialogClose()
  };
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setNewRegData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
  
    if (!newRegData.referenceId) newErrors.referenceId = 'Reference ID is required';
    if (!newRegData.assetTag) newErrors.assetTag = 'Asset tag is required';
    if (!newRegData.serial_number) newErrors.serial_number = 'Serial number is required';
    if (!newRegData.customer_name) newErrors.customer_name = 'Customer name is required';
    if (newRegData.call_type !== 'Refurbishment') {
      if (!newRegData.outlet_name) newErrors.outlet_name = 'Outlet name is required';
      if (!newRegData.status) newErrors.status = 'Warranty status is required';
    }
    if (!newRegData.address) newErrors.address = 'Address is required';
    if (!newRegData.state) newErrors.state = 'State is required';
    if (!newRegData.city) newErrors.city = 'City is required';
    if (!newRegData.phone_no) newErrors.phone_no = 'Phone number is required';
    if (!newRegData.model) newErrors.model = 'Model is required';
    if (!newRegData.service_provider) newErrors.service_provider = 'Service provider is required';
    if (!newRegData.appointment_date) newErrors.appointment_date = 'Appointment date is required';
    if (!newRegData.call_status) newErrors.call_status = 'Call status is required';
    if (!newRegData.tech_remark) newErrors.tech_remark = 'Technical remark is required';
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  useEffect(() => {
    fetchCustData()
    fetchStateData();
    fetchModalData()
    fetchServiceData()
  }, []);

  const fetchCustData = async () => {
    let custData = new FormData();
    custData.append("id", '');
    const fetchedCustomer = await fetchCustomerById(custData);
    console.log('fetchedCustomer', fetchedCustomer)
    if (fetchedCustomer) {
      setCustomer(fetchedCustomer?.customer);
    }
  };
  const fetchStateData = async () => {
    const fetchedStates = await fetchState();
    if (fetchedStates) {
      setStates(fetchedStates);
    }
  };

  const handleStateChange = async (event) => {
    const selectedStateID = event.target.value;
    const selectedState = states.find(state => state.StateID === selectedStateID);

    if (selectedState) {
      setNewRegData(prevData => ({
        ...prevData,
        state: selectedStateID // Update state directly to selectedStateID
      }));

      const cityData = new FormData();
      cityData.append("type", "city");
      cityData.append("value", selectedStateID);

      const fetchedCities = await fetchCity(cityData);

      if (fetchedCities) {
        setCities(fetchedCities);
        setNewRegData(prevData => ({
          ...prevData,
          city: "" // Reset city selection
        }));
      }
    }
  };

  const handleCityChange = async (event) => {
    const selectedCityID = event.target.value;
    const selectedCity = cities.find(city => city.id === selectedCityID);

    if (selectedCity) {
      setNewRegData(prevData => ({
        ...prevData,
        city: selectedCityID
      }));

      const subCityData = new FormData();
      subCityData.append("type", "city");
      subCityData.append("value", selectedCityID);

      const fetchedSubCities = await fetchSubCity(subCityData);

      if (fetchedSubCities) {
        setSubCities(fetchedSubCities);
        setNewRegData(prevData => ({
          ...prevData,
          subCity: ""
        }));
      }
    }
  };

  const fetchModalData = async () => {
    const fetchedModal = await fetchModel();
    if (fetchedModal) {
      setModals(fetchedModal);
    }
  };

  const fetchServiceData = async () => {
    const fetchedService = await fetchServiceProvider();
    if (fetchedService) {
      setServiceProvider(fetchedService);
    }
  };

  const handleReset = () => {
    setNewRegData({
      call_type: '',
      call_date: '',
      referenceId: '',
      assetTag: '',
      serial_number: '',
      customer_name: '',
      outlet_name: '',
      address: '',
      state: '',
      city: '',
      subCity: '',
      contact_person: '',
      phone_no: '',
      inv_date: '',
      status: '',
      model: '',
      service_provider: '',
      appointment_date: '',
      call_status: '',
      tech_remark: '',
    });
    setErrors({});
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      const userId = localStorage.getItem("user_id");
      try {
        const formData = new FormData();
        formData.append('call_type', newRegData.call_type);
        formData.append('call_date', newRegData.call_date);
        formData.append('case_number', newRegData.referenceId);
        formData.append('asset_number', newRegData.assetTag);
        formData.append('serial_number', newRegData.serial_number);
        formData.append('customer_number', newRegData.customer_name);
        formData.append('outlet_id', newRegData.outlet_name);
        formData.append('address_1', newRegData.address);
        formData.append('state_id', newRegData.state);
        formData.append('city', newRegData.city);
        formData.append('zip_code', newRegData.subCity);
        formData.append('contact_person', newRegData.contact_person);
        formData.append('phone_no', newRegData.phone_no);
        formData.append('inv_date', newRegData.inv_date);
        formData.append('status', newRegData.status);
        formData.append('model', newRegData.model);
        formData.append('service_provider', newRegData.service_provider);
        formData.append('appointment_date', newRegData.appointment_date);
        formData.append('call_status', newRegData.call_status);
        formData.append('tech_remark', newRegData.tech_remark);
        formData.append('created_by', userId);
  
        if (newRegData.call_status === 'Technical Closer' && pic.length === 0) {
          alert("No image to upload");
          return;
        }
  
        // Append images if available
        if (pic.length !== 0) {
          for (let i = 0; i < pic.length; i++) {
            formData.append('galerys[]', pic[i]);
          }
        }
  
        const res = await ConfigServe.addNewCall(formData);
        if (res.response.status === 200) {
          alert('Your update status is ' + res.response.message + ' . And your Complain no is ' + res.response.complaint_no);
          handleReset();
        } else {
          alert('Form submission failed');
        }
      } catch (error) {
        console.error('Error submitting form', error);
      }
    }
  };
  

  return (
    <Card>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <TextField
                label="Select Call Type"
                name="call_type"
                select
                value={newRegData.call_type}
                onChange={handleFormChange}
                error={!!errors.call_type}
                helperText={errors.call_type}
                fullWidth
              >
                {selectCallType.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Call Date"
                name="call_date"
                type="datetime-local"
                value={newRegData.call_date}
                onChange={handleFormChange}
                error={!!errors.call_date}
                helperText={errors.call_date}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Reference/Unique Id *"
                name="referenceId"
                value={newRegData.referenceId}
                onChange={handleFormChange}
                error={!!errors.referenceId}
                helperText={errors.referenceId}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Asset Tag *"
                name="assetTag"
                value={newRegData.assetTag}
                onChange={handleFormChange}
                error={!!errors.assetTag}
                helperText={errors.assetTag}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Serial Number *"
                name="serial_number"
                value={newRegData.serial_number}
                onChange={handleFormChange}
                error={!!errors.serial_number}
                helperText={errors.serial_number}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Customer Name *"
                name="customer_name"
                select
                value={newRegData.customer_name}
                onChange={handleFormChange}
                error={!!errors.customer_name}
                helperText={errors.customer_name}
                fullWidth
              >
                {custmoer.map((custData) => (
                  <MenuItem key={custData.id} value={custData.id}>
                    {custData.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {newRegData.call_type !== 'Refurbishment' && (
              <Grid item xs={12}>
                <TextField
                  label="Outlet Name *"
                  name="outlet_name"
                  value={newRegData.outlet_name}
                  onChange={handleFormChange}
                  error={!!errors.outlet_name}
                  helperText={errors.outlet_name}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                label="Address *"
                name="address"
                value={newRegData.address}
                onChange={handleFormChange}
                error={!!errors.address}
                helperText={errors.address}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="State *"
                name="state"
                select
                value={newRegData.state}
                onChange={handleStateChange}
                error={!!errors.state}
                helperText={errors.state}
                fullWidth
              >
                {states.map((state) => (
                  <MenuItem key={state.StateID} value={state.StateID}>
                    {state.StateName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="City *"
                name="city"
                select
                value={newRegData.city}
                onChange={handleCityChange}
                error={!!errors.city}
                helperText={errors.city}
                fullWidth
              >
                {cities.map((city) => (
                  <MenuItem key={city.id} value={city.id}>
                    {city.description}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Sub City"
                name="subCity"
                select
                value={newRegData.subCity}
                onChange={handleFormChange}
                error={!!errors.subCity}
                helperText={errors.subCity}
                fullWidth
              >
                {subcities.map((subCity) => (
                  <MenuItem key={subCity.id} value={subCity.id}>
                    {subCity.description}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Contact Person"
                name="contact_person"
                value={newRegData.contact_person}
                onChange={handleFormChange}
                error={!!errors.contact_person}
                helperText={errors.contact_person}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Phone No. *"
                name="phone_no"
                value={newRegData.phone_no}
                onChange={handleFormChange}
                error={!!errors.phone_no}
                helperText={errors.phone_no}
                fullWidth
              />
            </Grid>
            {newRegData.call_type !== 'Refurbishment' && (
            <Grid item xs={12} md={6}>
              <TextField
                label="Inv Date"
                name="inv_date"
                type="datetime-local"
                value={newRegData.inv_date}
                onChange={handleFormChange}
                error={!!errors.inv_date}
                helperText={errors.inv_date}
                fullWidth
              />
            </Grid>
            )}
            {newRegData.call_type !== 'Refurbishment' && (
            <Grid item xs={12}>
              <TextField
                label="Warranty Status *"
                name="status"
                select
                value={newRegData.status}
                onChange={handleFormChange}
                error={!!errors.status}
                helperText={errors.status}
                fullWidth
              >
                {warrantyStatus.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                label="Model *"
                name="model"
                select
                value={newRegData.model}
                onChange={handleFormChange}
                error={!!errors.model}
                helperText={errors.model}
                fullWidth
              >
                {modal.map((item) => (
                  <MenuItem key={item.model} value={item.model}>
                    {item.model}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Service Provider *"
                name="service_provider"
                select
                value={newRegData.service_provider}
                onChange={handleFormChange}
                error={!!errors.service_provider}
                helperText={errors.service_provider}
                fullWidth
              >
                <MenuItem value="Select">Select</MenuItem>
                {serviceProvider.map((item) => (
                  <MenuItem key={item.userid} value={item.userid}>{item.fname}{item.mname}{item.lname}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label={newRegData.call_type !== 'Refurbishment'?"Appointment Date *":"Incoming/receipt *"}
                name="appointment_date"
                type="datetime-local"
                value={newRegData.appointment_date}
                onChange={handleFormChange}
                error={!!errors.appointment_date}
                helperText={errors.appointment_date}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Call Status *"
                name="call_status"
                select
                value={newRegData.call_status}
                onChange={handleFormChange}
                error={!!errors.call_status}
                helperText={errors.call_status}
                fullWidth
              >
                {callStatus.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Technical Remark *"
                name="tech_remark"
                value={newRegData.tech_remark}
                onChange={handleFormChange}
                error={!!errors.tech_remark}
                helperText={errors.tech_remark}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} justifyContent="flex-start" >
              <Button onClick={handleUploadDialogOpen} color="primary">
                Upload Photo
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant='outlined' onClick={handleReset} color="secondary">
                Reset
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant='contained' type="submit" color="secondary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
      <UploadPhoto
        open={uploadDialogOpen}
        handleClose={handleUploadDialogClose}
        handleUpload={handleUpload}
      />
    </Card>
  );
};

export default NewRegisterCallForm;
