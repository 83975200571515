import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HowToRegIcon from '@mui/icons-material/HowToReg';import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';

// Map call status to color and icon
const statusConfig = {
  'Completed': { color: '#66bb6a', icon: <CheckCircleIcon fontSize="large" /> },
  'Pending': { color: '#ef5350', icon: <PendingIcon fontSize="large" /> },
  'Register': { color: '#42a5f5', icon: <HowToRegIcon fontSize="large" /> },
  'Reopen': { color: '#ffa726', icon: <ChangeCircleIcon fontSize="large" /> },
  'Scheduled': { color: '#ab47bc', icon: <CalendarTodayIcon fontSize="large" /> },
  'Technical Closer': { color: '#26c6da', icon: <SettingsIcon fontSize="large" /> },
};

const DashboardCard = ({ title, value, color, icon }) => {
  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={5} style={{ backgroundColor: color, color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {icon}
          </Grid>
          <Grid item xs={7} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '16px' }}>
            <Typography variant="h6" component="div">
              {title}
            </Typography>
            <Typography variant="h4">
              {value}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const DashboardCards = ({ dashboardData }) => {
  return (
    <Grid container spacing={2}>
      {dashboardData.map((data, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <DashboardCard 
            title={data.call_status} 
            value={data.COUNT} 
            color={statusConfig[data.call_status]?.color || '#cccccc'} 
            icon={statusConfig[data.call_status]?.icon || <SettingsIcon fontSize="large" />}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default DashboardCards;
