import React, { memo, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField, Button, Grid, DialogActions, IconButton, MenuItem, Select, FormControl, InputLabel, Chip, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UploadPhoto from './UploadPhoto';
import { fetchActualDefect, fetchCity, fetchCustomerById, fetchDefectCategory, fetchServiceProviderById, fetchState } from '../../utils/CommonFunction';
import PartReplacement from './PartReplacement';
import ConfigServe from '../../middleware/ConfigServe';

const UpdateRegistation = ({
    open,
    handleClose,
    formData,
    // handleSave,
    selectCallType,
}) => {
    console.log('formData', formData)
    const selectAssetCat = [
        { id: 1, label: "COOLER", value: "Cooler" },
        { id: 2, label: "FREEZER", value: "FREEZER" },
    ];

    const selectLocation = [
        { id: 1, label: "Local", value: "Local" },
        { id: 2, label: "Rural", value: "Rural" },
        { id: 3, label: "Up Country", value: "Up Country" },
    ];
    const selectPendingReason = [
        { id: 1, label: "Address not found", value: "Address not found" },
        { id: 2, label: "Shop Closed", value: "Shop Closed" },
        { id: 3, label: "Estimate given", value: "Estimate given" },
        { id: 4, label: "Appointment given by customer", value: "Appointment given by customer" },
    ];

    const [customerData, setCustomerData] = useState([]);
    const [serviceProData, setServiceProData] = useState([]);
    const [defectCatgData, setDefectCatgData] = useState([]);
    const [stateData, setStateData] = useState('');
    const [cityData, setCityData] = useState('');
    const [dropdownValue, setDropdownValue] = useState('');
    const [openAddPart, setOpenAddPart] = useState(false);
    const [actualDefect, setActualDefect] = useState([]);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [pic, setPic] = useState([]);
    const [parts, setParts] = useState([]);
    const [happyCode, setHappyCode] = useState('');
    const [locations, setLocations] = useState(null);
    const [error, setError] = useState('');
    const [updateData, setUpdateData] = useState({
        asset_Category: formData?.asset_Category || "Cooler",
        actual_defect_cat: formData?.actual_defect_cat || '',
        actual_defect: actualDefect || "",
        location: formData?.location || "Local",
        // part_replaced: formData?.part_replaced,
        appointment_date: formData?.appointment_date || '',
        call_status: '',
        pendingReason: formData?.pendingReason || '',
        tech_remark: formData?.tech_remark || '',
    })

    const handleReset = () => {
        setUpdateData({
            asset_Category: "Cooler",
            actual_defect_cat: '',
            actual_defect: [],
            location: "Local",
            appointment_date: '',
            call_status: '',
            pendingReason: '',
            tech_remark: '',
        })
        setPic('')
    }

    useEffect(() => {
        // Request user's location when component mounts
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocations({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                    console.log(locations)
                    setError('');
                },
                (error) => {
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            setError('User denied the request for Geolocation.');
                            break;
                        case error.POSITION_UNAVAILABLE:
                            setError('Location information is unavailable.');
                            break;
                        case error.TIMEOUT:
                            setError('The request to get user location timed out.');
                            break;
                        default:
                            setError('An unknown error occurred.');
                            break;
                    }
                }
            );
        } else {
            setError('Geolocation is not supported by this browser.');
        }
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUpdateData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleChipDelete = (chipToDelete) => {
        setUpdateData((prevData) => ({
            ...prevData,
            actual_defect: prevData.actual_defect.filter((value) => value !== chipToDelete),
        }));
    };

    // **Define functions to manage uploadPhoto dialog **//
    const handleUploadDialogOpen = () => {
        setUploadDialogOpen(true);
    };

    const handleUploadDialogClose = () => {
        setUploadDialogOpen(false);
    };

    const handleUpload = (filePreviews) => {
        console.log('Files to upload:', filePreviews);
        setPic(filePreviews)
        handleUploadDialogClose()
    };
    const handlePartSave = (partRplace) => {
        console.log('Parts to replace:', partRplace);
        setParts(partRplace)
        handleDialogClose()
    };
    const fetchCustomerData = async () => {
        let custData = new FormData();
        custData.append("id", formData.customer_number);
        const fetchedCustomer = await fetchCustomerById(custData);
        if (fetchedCustomer) {
            setCustomerData(fetchedCustomer?.customers[0]);
        }
    };

    const fetchServiceProviderData = async () => {
        let serviceData = new FormData();
        serviceData.append("userid", formData.service_provider);
        const fetchedSP = await fetchServiceProviderById(serviceData);
        if (fetchedSP) {
            setServiceProData(fetchedSP[0]);
        }
    };

    const fetchDefectCatg = async () => {
        const fetchedDefectCatg = await fetchDefectCategory();
        if (fetchedDefectCatg) {
            setDefectCatgData(fetchedDefectCatg);
        }
    };

    const fetchActualDefectData = async () => {
        let actualDefectData = new FormData();
        actualDefectData.append("value", updateData.actual_defect_cat)
        actualDefectData.append("cat_type", 'Cooler')
        actualDefectData.append("call_type", "Service Order")
        const fetchedAD = await fetchActualDefect(actualDefectData)
        if (fetchedAD) {
            setActualDefect(fetchedAD)
        }
    }

    useEffect(() => {
        fetchCustomerData()
        fetchServiceProviderData()
        fetchState()
        fetchDefectCatg()
        // fetchActualDefectData()
        // fetchCity()
    }, [])

    useEffect(() => {
        fetchActualDefectData()
    }, [updateData.actual_defect_cat])

    const handleDropdownChange = (event) => {
        setDropdownValue(event.target.value);
        handleChange(event);
        if (event.target.value === 'Part Call') {
            setOpenAddPart(true);
        }
    };

    const handleDialogClose = () => {
        setOpenAddPart(false);
    };

    // Function to handle saving updated data
    const handleSaveUpdatedData = async () => {
        const userId = localStorage.getItem("user_id");
        if (!locations?.latitude || !locations?.longitude) {
            alert('Location data is missing. Please ensure your GPS is enabled and try again.');
            return;
        }
        // if (happyCode !== formData?.happy_code) {
        //     alert('Happy code does not match. Please click the "Resend Happy code" button.');
        //     return;
        // }
        if (dropdownValue === 'Part Call' && parts.length === 0) {
            alert('You have selected Part Call...Please add valid Parts');
            return;
        }
        if (updateData.call_status === 'Technical Closer' && pic.length === 0) {
            alert('Please upload an image')
        }
        try {
            const updateFormData = new FormData();
            updateFormData.append('call_type', formData.call_type);
            updateFormData.append('call_date', formData.call_date);
            updateFormData.append('case_number', formData.case_number);
            updateFormData.append('asset_number', formData.asset_number);
            updateFormData.append('serial_number', formData.serial_number);
            updateFormData.append('customer_number', formData.customer_number);
            updateFormData.append('outlet_id', formData.outlet_name);
            updateFormData.append('address_1', formData.address_1);
            updateFormData.append('state_id', formData.state_id);
            updateFormData.append('city', formData.city);
            updateFormData.append('zip_code', formData.zip_code);
            updateFormData.append('contact_person', formData.contact_person);
            updateFormData.append('phone_no', formData.phone_no);
            updateFormData.append('inv_date', formData.inv_date);
            updateFormData.append('status', formData.status);
            updateFormData.append('model', formData.model);
            updateFormData.append('service_provider', formData.service_provider);
            updateFormData.append('call_status', updateData.call_status);
            updateFormData.append('tech_remark', updateData.tech_remark);
            updateFormData.append('defect_reported[]', formData.defect_reported);
            updateFormData.append('appointment_date', updateData.appointment_date);
            updateFormData.append('branch_id', formData.branch_id);
            updateFormData.append('customer_id', '');
            updateFormData.append('updated_by', userId);
            updateFormData.append('id', formData.case_id);
            updateFormData.append('actual_defect[]', updateData.actual_defect);
            updateFormData.append('location', updateData.location);
            updateFormData.append('pending_reason', updateData.pendingReason);
            updateFormData.append('galerys[]', pic[0]);
            updateFormData.append('galerys[]', pic[1]);
            updateFormData.append('galerys[]', pic[2]);
            updateFormData.append('galerys[]', pic[3]);
            updateFormData.append('galerys[]', pic[4]);
            updateFormData.append('part_replaced', dropdownValue);
            updateFormData.append('qty[]', parts.quantities);
            updateFormData.append('part_id[]', parts.parts);
            updateFormData.append('oldmodel[]', parts.oldModels);
            updateFormData.append('oldmodelsno[]', parts.oldSerials);
            updateFormData.append('newmodel[]', parts.newModels);
            updateFormData.append('newmodelsno[]', parts.newSerials);
            updateFormData.append('longitude', locations.longitude);
            updateFormData.append('latitude', locations.latitude);
            const res = await ConfigServe.updateCall(updateFormData)
            if (res.response.status === 200) {
            alert('your update status is ' + res.response.message);
            handleReset()
            handleClose()
             }
             else {
             alert('Form submission failed');
             }
            // }
            // else {
            //     alert("no image to upload")
            // }
        } catch (error) {
            alert('error')
        }
    };

    const handleHappyCode = async () => {
        try {
            const happyCodeData = new FormData();
            happyCodeData.append('value', formData.case_id);
            const res = await ConfigServe.gethappycode(happyCodeData)
            console.log('res', res)
        } catch (error) {

        }
    }
    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle sx={{ backgroundColor: "#bef2ff" }}>Update Call</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Select Call Type"
                                name="callType"
                                value={formData?.call_type ?? ""}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Call Date"
                                name="callDate"
                                value={formData?.call_date ?? ""}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Reference/Unique Id"
                                name="referenceId"
                                value={formData?.serial_number ?? ""}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <TextField
                                label="Asset Tag"
                                name="assetTag"
                                value={formData?.asset_number ?? ""}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Serial Number"
                                name="serialNumber"
                                value={formData?.serial_number ?? ""}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Customer Name"
                                name="customerName"
                                value={customerData.retail_name ?? ""}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Outlet Name"
                                name="outletName"
                                value={formData?.outlet_id ?? ""}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Address"
                                name="address"
                                value={formData?.address_1 ?? ""}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="State"
                                name="state"
                                value={formData.state_id ?? ""}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="City"
                                name="city"
                                value={formData?.city ?? ""}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Sub City"
                                name="subCity"
                                value={formData?.zip_code ?? ""}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Contact Person"
                                name="contactPerson"
                                value={formData?.contact_person ?? ""}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Phone No."
                                name="phoneNo"
                                value={formData?.phone_no ?? ""}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Inv Date"
                                name="invDate"
                                value={formData?.inv_date ?? ""}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Warranty Status"
                                name="status"
                                value={formData?.status ?? ""}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Model"
                                name="model"
                                value={formData?.model ?? ""}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Service Provider"
                                name="service_provider"
                                value={`${serviceProData.fname ?? ""} ${serviceProData.mname ?? ""} ${serviceProData.lname ?? ""}`.trim()}
                                fullWidth
                                disabled
                            />
                        </Grid> */}
                        <Grid item xs={12}>
                            <TextField
                                label="Defect reported"
                                name="defect_reported"
                                value={formData?.defect_reported ?? ""}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Asset Catg."
                                name="asset_Category"
                                value={updateData?.asset_Category ?? ""}
                                onChange={handleChange}
                                fullWidth
                                select
                            >
                                {selectAssetCat.map((opt) => (
                                    <MenuItem key={opt.value} value={opt.value}>
                                        {opt.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="OP/ Defect Catg."
                                name="actual_defect_cat"
                                value={updateData?.actual_defect_cat ?? ""}
                                onChange={handleChange}
                                fullWidth
                                select
                            >
                                <MenuItem value='0'>
                                    Select
                                </MenuItem>
                                {defectCatgData.map((opt) => (
                                    <MenuItem key={opt.id} value={opt.id}>
                                        {opt.cat_code}-{opt.cat_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="actual-defect-label">Select Actual Defect</InputLabel>
                                <Select
                                    labelId="actual-defect-label"
                                    id="actual-defect"
                                    name="actual_defect"
                                    multiple
                                    value={updateData.actual_defect}
                                    onChange={handleChange}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => {
                                                const defect = actualDefect.find((option) => option.id === value);
                                                return <Chip key={value}
                                                    label={defect ? defect.desc : ''}
                                                    onDelete={() => handleChipDelete(value)}
                                                />;
                                            })}
                                        </Box>
                                    )}
                                >
                                    {actualDefect.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.desc}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Location"
                                name="location"
                                value={updateData?.location ?? ""}
                                onChange={handleChange}
                                fullWidth
                                select
                            >
                                {selectLocation.map((opt) => (
                                    <MenuItem key={opt.value} value={opt.value}>
                                        {opt.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Part Replacement"
                                name="part_replaced"
                                value={dropdownValue}
                                onChange={handleDropdownChange}
                                fullWidth
                                select
                            >
                                <MenuItem value="No Part Call">No Part Call</MenuItem>
                                <MenuItem value="Part Call">Part Call</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Appointment Date"
                                name="appointment_date"
                                type="datetime-local"
                                value={updateData?.appointment_date ?? ""}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Call Status"
                                name="call_status"
                                value={updateData?.call_status ?? ""}
                                onChange={handleChange}
                                select
                                fullWidth
                            >
                                {selectCallType.map((opt) => (
                                    <MenuItem key={opt.value} value={opt.value}>
                                        {opt.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Pending Reason"
                                name="pendingReason"
                                value={updateData?.pendingReason ?? ""}
                                onChange={handleChange}
                                select
                                fullWidth
                            >
                                {selectPendingReason.map((opt) => (
                                    <MenuItem key={opt.value} value={opt.value}>
                                        {opt.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Technical Remark"
                                name="tech_remark"
                                value={updateData?.tech_remark ?? ""}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button onClick={handleUploadDialogOpen} color="primary">
                                Upload Photo
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Happy Code"
                                name="happy_code"
                                onChange={(e) => setHappyCode(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button color="secondary" onClick={handleHappyCode}>
                                Resend Happy code
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: "#bef2ff" }}>
                    <Button onClick={handleClose} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    {
                        <Button onClick={handleSaveUpdatedData} variant="contained" color="primary">
                            Update
                        </Button>}
                </DialogActions>
            </Dialog>
            <UploadPhoto
                open={uploadDialogOpen}
                handleClose={handleUploadDialogClose}
                handleUpload={handleUpload}
            />
            {openAddPart &&
                <PartReplacement
                    open={openAddPart}
                    onClose={handleDialogClose}
                    handlePartSave={handlePartSave}
                />}
        </>
    );
};

export default memo(UpdateRegistation)
