import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, Button, Stack, Divider, Box, Alert, CircularProgress } from '@mui/material';

const PMCallsGridList = ({
  fetchedData,
  subtitles,
  dataKeys,
  handleUpdate,
  handlePartsView,
  handleImagesView
}) => {
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleString('en-US', options);
  };

  if (fetchedData.length === 0) {
    return (
      <Typography variant="h6" align="center" gutterBottom>
        No Records Found
      </Typography>
    );
  }

  return (
    <>
      {fetchedData.map((data, index) => (
        <Box mb={2} key={index}>
          <Card sx={{ backgroundColor: "#feffea" }}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      backgroundColor: '#49796B',
                      color: 'white',
                      fontWeight: 'bold',
                      padding: '8px',
                      borderRadius: '20px'
                    }}
                  >
                    {formatDate(data.created_on_date)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction={'row'} alignItems="left" sx={{ justifyContent: 'space-between', px: '8px' }}>
                    <Typography variant="body" gutterBottom color="primary" sx={{ fontWeight: 'bold' }}>
                      Complaint No:
                    </Typography>
                    <Typography variant="body1" gutterBottom color="primary">
                      {data.complaint_no}
                    </Typography>
                  </Stack>
                  <Divider />
                </Grid>
                {subtitles.map((subtitle, i) => (
                  <Grid item xs={12} key={i}>
                    <Stack direction={'row'} alignItems="left" sx={{ justifyContent: 'space-between', px: '8px' }}>
                      <Typography variant="body" gutterBottom color="primary" sx={{ fontWeight: 'bold' }}>
                        {subtitle}
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        {data[dataKeys[i]]}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  {(data.call_status !== 'Completed' && data.call_status !== 'Technical Closer') && (
                    <Button onClick={() => handleUpdate(data)} variant="contained" color="primary">
                      Update
                    </Button>
                  )}
                </Grid>
                {(data.call_status === 'Completed' || data.call_status === 'Technical Closer') && (
                  <>
                    <Grid item xs={6}>
                      <Button variant="contained" color="primary" onClick={() => handlePartsView(data)}>
                        View Used Parts
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button variant="contained" color="secondary" onClick={() => handleImagesView(data)}>
                        View Images
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      ))}
    </>
  );
};

export default PMCallsGridList;
