import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    IconButton,
    Box,
    MenuItem,
    Grid
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchPart } from '../../utils/CommonFunction';

const PartReplacement = ({ open, onClose, handlePartSave }) => {
    const [parts, setParts] = useState([{ part: '', quantity: 1, oldModel: '', oldSerial: '', newModel: '', newSerial: '' }]);
    const [options, setOptions] = useState([]);
    const specialIpIds = ["37", "39", "60", "111", "125", "126", "155", "184", "199", "209", "216", "226", "198", "225", "22", "68", "69", "102", "129", "130", "146", "148", "186", "188", "210", "211", "228", "21"];

    const fetchOptions = async () => {
        const selectParts = await fetchPart();
        if (selectParts) {
            setOptions(selectParts);
        }
    };

    useEffect(() => {
        fetchOptions();
    }, []);

    const handlePartChange = (index, value) => {
        const newParts = [...parts];
        newParts[index].part = value;
        setParts(newParts);
    };

    const handleQuantityChange = (index, value) => {
        const newParts = [...parts];
        newParts[index].quantity = value;
        setParts(newParts);
    };

    const handleOldModelChange = (index, value) => {
        const newParts = [...parts];
        newParts[index].oldModel = value;
        setParts(newParts);
    };

    const handleOldSerialChange = (index, value) => {
        const newParts = [...parts];
        newParts[index].oldSerial = value;
        setParts(newParts);
    };

    const handleNewModelChange = (index, value) => {
        const newParts = [...parts];
        newParts[index].newModel = value;
        setParts(newParts);
    };

    const handleNewSerialChange = (index, value) => {
        const newParts = [...parts];
        newParts[index].newSerial = value;
        setParts(newParts);
    };

    const handleAddClick = () => {
        setParts([...parts, { part: '', quantity: 1, oldModel: '', oldSerial: '', newModel: '', newSerial: '' }]);
    };

    const handleDeleteClick = (index) => {
        const newParts = parts.filter((_, i) => i !== index);
        setParts(newParts);
    };

    const getAvailableOptions = (index) => {
        const selectedParts = parts.map((part, idx) => (idx !== index ? part.part : null)).filter(Boolean);
        return options.filter(option => !selectedParts.includes(option.ip_id));
    };

    const isSpecialPart = (partId) => specialIpIds.includes(partId);

    const transformPartsData = (parts) => {
        const partsArray = parts.map(part => part.part);
        const quantitiesArray = parts.map(part => part.quantity);
        const oldModelsArray = parts.map(part => part.oldModel);
        const oldSerialsArray = parts.map(part => part.oldSerial);
        const newModelsArray = parts.map(part => part.newModel);
        const newSerialsArray = parts.map(part => part.newSerial);

        return {
            parts: partsArray,
            quantities: quantitiesArray,
            oldModels: oldModelsArray,
            oldSerials: oldSerialsArray,
            newModels: newModelsArray,
            newSerials: newSerialsArray
        };
    };

    const handleSave = () => {
        const transformedData = transformPartsData(parts);
        handlePartSave(transformedData);
        setParts([{ part: '', quantity: 1, oldModel: '', oldSerial: '', newModel: '', newSerial: '' }]);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Part Call</DialogTitle>
            <DialogContent>
                {parts.map((item, index) => (
                    <Box key={index} display="flex" flexDirection="column" marginBottom={1}>
                        <Box display="flex" alignItems="center">
                            <TextField
                                label="Part"
                                value={item.part}
                                onChange={(e) => handlePartChange(index, e.target.value)}
                                fullWidth
                                margin="dense"
                                select
                            >
                                {getAvailableOptions(index).map((option) => (
                                    <MenuItem key={option.ip_id} value={option.ip_id}>
                                        {option.part_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                label="Quantity"
                                value={item.quantity}
                                onChange={(e) => handleQuantityChange(index, e.target.value)}
                                fullWidth
                                margin="dense"
                                style={{ marginLeft: 8 }}
                            />
                            <IconButton onClick={handleAddClick} color="primary" style={{ marginLeft: 8 }}>
                                <AddIcon />
                            </IconButton>
                            {parts.length > 1 && (
                                <IconButton onClick={() => handleDeleteClick(index)} style={{ color: 'red' }}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </Box>
                        {isSpecialPart(item.part) && (
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Old Model"
                                        value={item.oldModel}
                                        onChange={(e) => handleOldModelChange(index, e.target.value)}
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Old Serial No"
                                        value={item.oldSerial}
                                        onChange={(e) => handleOldSerialChange(index, e.target.value)}
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="New Model"
                                        value={item.newModel}
                                        onChange={(e) => handleNewModelChange(index, e.target.value)}
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="New Serial No"
                                        value={item.newSerial}
                                        onChange={(e) => handleNewSerialChange(index, e.target.value)}
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                {/* <Button onClick={() => handlePartSave(parts)} color="primary"> */}
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PartReplacement;
