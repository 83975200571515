import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText, useMediaQuery, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logoNew from '../../assets/images/logo-new.svg';

const LoginAppBar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:768px)');

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setIsDrawerOpen(open);
  };

  useEffect(() => {
    if (!isSmallScreen) {
      setIsDrawerOpen(false);
    }
  }, [!isSmallScreen]); 

  return (
    <div>
      <AppBar position="fixed" sx={{backgroundColor:"#1acdee"}}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img src={logoNew} alt="Logo" style={{ maxWidth: '100px', verticalAlign: 'middle' }} />
          </Typography>
          {isSmallScreen && (
            <></>
            // <IconButton
            //   size="large"
            //   edge="start"
            //   color="inherit"
            //   aria-label="menu"
            //   sx={{ mr: 2 }}
            //   onClick={toggleDrawer(true)}
            // >
            //   <MenuIcon />
            // </IconButton>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isSmallScreen && (
              <>
            {/* //     <ListItem button>
            //       <ListItemText primary="Home"  />
            //     </ListItem>
            //     <ListItem button>
            //       <ListItemText primary="Warranty Registration" />
            //     </ListItem>
            //     <ListItem button>
            //       <ListItemText primary="Service Registration"  />
            //     </ListItem> */}
             </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {isSmallScreen && (
        // <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <>
        {/* //   <List>
        //     <ListItem button>
        //       <ListItemText primary="Home" />
        //     </ListItem>
        //     <ListItem button>
        //       <ListItemText primary="Warranty Registration" />
        //     </ListItem>
        //     <ListItem button>
        //       <ListItemText primary="Service Registration" />
        //     </ListItem>
        //   </List>
        // </Drawer> */}
        </>
      )}
    </div>
  );
};

export default LoginAppBar;
