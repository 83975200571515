import React from 'react'
import Login from '../../components/login/Login'
import LoginAppBar from '../../layout/header/LoginAppBar'


const HomePage = () => {
  return (
    <>
     <LoginAppBar/>
      <Login/>
    </>
  )
}

export default HomePage
