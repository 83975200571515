import './App.css';
import AppRoute from './routes/appRoutes/AppRoute';

function App() {
  return (
    <div className="App">
      <header>
        <AppRoute/>
      </header>
    </div>
  );
}

export default App;