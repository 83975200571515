import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ViewPartsList = ({ open, handleClose, part }) => {
  const hasParts = part && part.part_id && part.part_id.length > 0;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Parts List
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {hasParts ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell><Typography variant="body2" color="primary" fontWeight="bold">Part ID</Typography></TableCell> */}
                  <TableCell><Typography variant="body2" color="primary" fontWeight="bold">Part Name</Typography></TableCell>
                  <TableCell><Typography variant="body2" color="primary" fontWeight="bold">Quantity</Typography></TableCell>
                  <TableCell><Typography variant="body2" color="primary" fontWeight="bold">Old Model</Typography></TableCell>
                  <TableCell><Typography variant="body2" color="primary" fontWeight="bold">Old Model S/N</Typography></TableCell>
                  <TableCell><Typography variant="body2" color="primary" fontWeight="bold">New Model</Typography></TableCell>
                  <TableCell><Typography variant="body2" color="primary" fontWeight="bold">New Model S/N</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {part.part_id.map((id, index) => (
                  <TableRow key={id}>
                    {/* <TableCell>{id}</TableCell> */}
                    <TableCell>{part.part_name[index]}</TableCell>
                    <TableCell>{part.qty[index]}</TableCell>
                    <TableCell>{part.oldmodel[index] || 'N/A'}</TableCell>
                    <TableCell>{part.oldmodelsno[index] || 'N/A'}</TableCell>
                    <TableCell>{part.newmodel[index] || 'N/A'}</TableCell>
                    <TableCell>{part.newmodelsno[index] || 'N/A'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No parts listed.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewPartsList;
