import ConfigServe from "../middleware/ConfigServe";

export const fetchState = async () => {
    try {
        const res = await ConfigServe.getallstates();
        if (res.response.status === 200) {
            return res.state;
        }
    } catch (error) {
        console.error(error);
    }
};

export const fetchCity = async (data) => {
    try {
        const res = await ConfigServe.getCity(data);
        if (res.response.status === 200) {
            return res.city;
        }
    } catch (error) {
        console.error(error);
    }
};

export const fetchCityByCityId = async (data) => {
    try {
        const res = await ConfigServe.getCityByCityId(data);
        if (res.response.status === 200) {
            return res.city;
        }
    } catch (error) {
        console.error(error);
    }
};

export const fetchSubCity = async (data) => {
    try {
        const res = await ConfigServe.getSubCity(data);
        if (res.response.status === 200) {
            return res.sub_city;
        }
    } catch (error) {
        console.error(error);
    }
};

export const fetchModel = async () => {
    try {
        const res = await ConfigServe.getallmodels();
        if (res.response.status === 200) {
            return res.model;
        }
    } catch (error) {
        console.error(error);
    }
};

export const fetchServiceProvider = async () => {
    try {
        const res = await ConfigServe.getallServiceProvider();
        if (res.response.status === 200) {
            return res.service_provider;
        }
    } catch (error) {
        console.error(error);
    }
};

export const fetchCustomerById = async (data) => {
    try {
        const res = await ConfigServe.getCustomerById(data);
        if (res) {
            return res;
        }
        else{
            alert('error')
        }
    } catch (error) {
        console.error(error);
    }
};

export const fetchServiceProviderById = async (data) => {
    try {
        const res = await ConfigServe.getServiceProviderById(data);

        if (res.response.status === 200) {
            return res.service_provider;
        }
        else{
            alert('error')
        }
    } catch (error) {
        console.error(error);
    }
};

export const fetchDefectCategory = async () => {
    try {
        const res = await ConfigServe.getDefectcatg();
        if (res.response.status === 200) {
            return res.defect_cat;
        }
    } catch (error) {
        console.error(error);
    }
};

export const fetchPart = async () => {
    try {
        const res = await ConfigServe.getparts();
        if (res.response.status === 200) {
            return res.parts;
        }
    } catch (error) {
        console.error(error);
    }
};

export const fetchActualDefect = async (data) => {
    try {
        const res = await ConfigServe.getActualDefect(data);
console.log('res', res)
        if (res.response.status === 200) {
            return res.actual_defect;
        }
        else{
            alert('error')
        }
    } catch (error) {
        console.error(error);
    }
};