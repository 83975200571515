import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Grid, Button, IconButton, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

const UploadPhoto = ({ 
    open, 
    handleClose,
    handleUpload,
}) => {

    const labels = [
        "Shop Name / Sign Board",
        "Model, Serial & Asset",
        "Cooler front side",
        "Owner Photo/Signature",
        "Defective Part"
    ];

    const [filePreviews, setFilePreviews] = useState(labels.map(() => null));

    const handleFileChange = (event, index) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            const file = files[0]; // Only consider the first file
            const reader = new FileReader();
            reader.onloadend = () => {
                const newFilePreviews = [...filePreviews];
                newFilePreviews[index] = reader.result; // Replace the existing file preview
                setFilePreviews(newFilePreviews);
            };
            reader.readAsDataURL(file);
        }
    };

    const removePreview = (index) => {
        const newFilePreviews = [...filePreviews];
        newFilePreviews[index] = null; // Set the preview to null
        setFilePreviews(newFilePreviews);
    };

    const handleUploadClick = () => {
        handleUpload(filePreviews);
        setFilePreviews(labels.map(() => null));
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                Upload Photo
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {labels.map((label, index) => (
                        <Grid item xs={12} key={index}>
                            <Button
                                variant="contained"
                                component="label"
                                startIcon={<PhotoCamera />}
                                fullWidth
                                sx={{
                                    justifyContent: 'flex-start',
                                    backgroundColor: '#42a5f5',
                                    color: 'white',
                                    marginBottom: '8px',
                                }}
                            >
                                {label}
                                <input
                                    type="file"
                                    name={`file${index + 1}`}
                                    onChange={(e) => handleFileChange(e, index)}
                                    hidden
                                />
                            </Button>
                            {filePreviews[index] && (
                                <Grid container>
                                    <Grid item xs={12}>
                                        <div style={{ position: 'relative' }}>
                                            <IconButton
                                                onClick={() => removePreview(index)}
                                                style={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}
                                            >
                                                <CloseIcon style={{ color: 'white', backgroundColor: 'transparent' }} />
                                            </IconButton>
                                            <img
                                                src={filePreviews[index]}
                                                alt={`Preview ${index}`}
                                                style={{
                                                    width: '100%',
                                                    maxHeight: '150px',
                                                    marginTop: '8px',
                                                    borderRadius: '4px',
                                                    objectFit: 'cover'
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button variant="outlined" onClick={handleUploadClick} color="primary">
                    Upload
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UploadPhoto;
