import React from 'react'
import Header from '../../layout/header/Header'
import { Grid, Box } from '@mui/material';
import CallListView from '../../components/callListView/CallListView';


const CallListViewPage = () => {
  return (
    <>
     <Grid container spacing={6}>
      <Grid item xs={12}>
        <Box>
          <Header />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
            <CallListView/>
        </Box>
      </Grid>
    </Grid>
    </>
  )
}

export default CallListViewPage