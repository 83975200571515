import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, CircularProgress, Alert } from '@mui/material';
import PMCallsSearch from '../../components/pmCalls/PMCallsSearch';
import PMCallsGridList from '../pmCalls/PMCallsGridList';
import UpdateRegistration from '../registerCallList/UpdateRegistration';
import UploadPhoto from '../registerCallList/UploadPhoto';
import ConfigServe from '../../middleware/ConfigServe';
import ViewPartsList from './ViewPartsList';
import ViewImageList from './ViewImageList';

const CallListView = () => {
  const optionData = [
    { id: 0, label: 'All', value: 'all' },
    { id: 1, label: 'Pending', value: 'Pending' },
    { id: 2, label: 'Completed', value: 'Completed' },
    { id: 3, label: 'Scheduled', value: 'Scheduled' },
    { id: 4, label: 'Reopen', value: 'Reopen' },
    { id: 5, label: 'Register', value: 'Register' },
    { id: 6, label: 'Technical Closer', value: 'Technical Closer' },
  ];
  
  const selectCallType = [
    { id: 1, label: "Scheduled", value: "Scheduled" },
    { id: 2, label: "Pending", value: "Pending" },
    { id: 3, label: "Technical Closer", value: "Technical Closer" },
];
  const subtitles = [
    'Serial Number:',
    'Outlet Name:',
    'Address:',
    'Phone No:',
    'Call Type:',
    'Status:',
  ];

  const dataKeys = [
    'serial_number',
    'outlet_id',
    'address_1',
    'phone_no',
    'call_type',
    'call_status',
  ];

  const labels = [
    'Shop Name / Sign Board',
    'Model, Serial & Asset',
    'Cooler front side',
    'Owner Photo/Signature',
    'Defective Part',
  ];

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedOption, setSelectedOption] = useState(optionData[6].value);
  const [overallSearch, setOverallSearch] = useState('');
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCall, setSelectedCall] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [partsDilogOpen, setPartsDialogOpen] = useState(false);
  const [partCaseId, setPartCaseId] = useState(null);
  const [part, setPart] = useState([]);
  const [imgDilogOpen, setImgDialogOpen] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [imgCaseId, setImgCaseId] = useState(null);
  const [images, setImages] = useState([]);
  const [filePreviews, setFilePreviews] = useState(labels.map(() => []));
 //** API call for RegiterCallList**//
  const fetchCallList = async () => {
    const user_id = localStorage.getItem('user_id');
    const designation_id = localStorage.getItem('designation_id');
    const user_type = localStorage.getItem('user_type');
    const cust_id = localStorage.getItem('cust_id');

    try {
      let callListData = new FormData();
      callListData.append('user_id', user_id);
      callListData.append('designation_id', designation_id);
      callListData.append('user_type', user_type);
      callListData.append('cust_id', cust_id);
      callListData.append('sdata', overallSearch);
      callListData.append('sdate', fromDate);
      callListData.append('edate', toDate);
      callListData.append('status', selectedOption);
      setLoading(true);
      const res = await ConfigServe.allServceCallList(callListData);
      if (res.response.status === 200) {
        setFetchedData(res.cases);
        setAlertMessage('');
      } else {
        setFetchedData([]);
        setAlertMessage('No file matched. Please check the search term');
      }
    } catch (error) {
      console.error(error);
      setAlertMessage('An error occurred while fetching the data.');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCallList();
  }, []);

  const handleOverallSearch = (e) => {
    setOverallSearch(e.target.value);
  };

  const handleFromDate = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDate = (e) => {
    setToDate(e.target.value);
  };

  const handleOption = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSearch = () => {
    fetchCallList();
  };

  const handleUpdate = (data) => {
    setSelectedCall(data);
    setDialogOpen(true);
  };

//   useEffect(() => {
//     if (selectedCall) {
//       setDialogOpen(true);
//     }
//   }, [selectedCall]);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSaveUpdatedData = (updatedData) => {
    console.log('Updated Call Data:', updatedData);
    setDialogOpen(false);
  };

  const handleUploadDialogOpen = () => {
    setUploadDialogOpen(true);
  };

  const handleUploadDialogClose = () => {
    setUploadDialogOpen(false);
  };

  const handleFileChange = (event, index) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      const newFilePreviews = [...filePreviews];
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          newFilePreviews[index].push(reader.result);
          setFilePreviews([...newFilePreviews]);
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const removePreview = (labelIndex, previewIndex) => {
    const newFilePreviews = [...filePreviews];
    newFilePreviews[labelIndex].splice(previewIndex, 1);
    setFilePreviews(newFilePreviews);
  };

  const handleUpload = () => {
    // Implement upload logic here
  };
  
  const handlePartsDialogClose = () => {
    setPart([]);
    setPartsDialogOpen(false);
  };
  const handlePartsView = (data) =>{
   setPartsDialogOpen(true)
   setPartCaseId(data.case_id)
  }

  const fetchparts = async () => {
    try {
      let partsData = new FormData();
      partsData.append('id', partCaseId);
      const res = await ConfigServe.getCasesUsedParts(partsData)
      console.log('resparts', res)
      if (res.response.status === 200) {
        setPart(res.part);
      } else {
        setPart([]);
      }
    } catch (error) {
      
    }
  }
   useEffect(()=>{
    if (partCaseId !== null){
      fetchparts()
    }
   },[partCaseId])

  const handleImagesDialogClose = () => {
    setImgDialogOpen(false);
    setClicked(false)
    setImages([]);
    
  };
  const handleImagesView = (data) =>{
    setImgDialogOpen(true)
    setImgCaseId(data.case_id)
    setClicked(true)
   }
   const transformImageData = (data) => {
    const transformedData = data.image.map((_, index) => ({
      image: data.image[index],
      address: data.address[index],
      latitude: data.latitude[index],
      longitude: data.longitude[index],
      outlet_name: data.outlet_name[index],
      ref_unique_id: data.ref_unique_id[index],
      serial_no: data.serial_no[index],
    }));
  
    return transformedData;
  };

   const fetchImages = async () => {
  try {
    let imagesData = new FormData();
    imagesData.append('id', imgCaseId);
    const res = await ConfigServe.getCasesImages(imagesData);
    console.log('res', res.response);
    if (res.response.status === 200) {
      const transformedImages = transformImageData(res.response);
      setImages(transformedImages);
      console.log('transformedImages', transformedImages)
    }
    else{
      setImages([])
    }
  } catch (error) {
    console.error('Error fetching images', error);
  }
};
   useEffect(()=>{
    if (imgCaseId !== null && clicked === true){
      fetchImages()
    }
   },[imgCaseId,clicked])
  return (
    <>
      <Box sx={{ backgroundColor: '#bef2ff' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box mt={2} mx={1}>
              <Typography variant="h6" align="center" gutterBottom>
                Call List View
              </Typography>
              <PMCallsSearch
                fromDate={fromDate}
                toDate={toDate}
                overallSearch={overallSearch}
                selectedOption={selectedOption}
                optionData={optionData}
                handleFromDate={handleFromDate}
                handleToDate={handleToDate}
                handleOverallSearch={handleOverallSearch}
                handleOption={handleOption}
                onSearch={handleSearch}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mx={1}>
              {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" mt={5} height="200px">
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {alertMessage && <Alert severity="warning">{alertMessage}</Alert>}
                  <PMCallsGridList
                    fetchedData={fetchedData}
                    subtitles={subtitles}
                    dataKeys={dataKeys}
                    handleUpdate={handleUpdate}
                    handlePartsView={handlePartsView}
                    handleImagesView={handleImagesView}
                  />
                </>
              )}
            </Box>
          </Grid>
        </Grid>
        {selectedCall &&
        <UpdateRegistration
          open={dialogOpen}
          handleClose={handleCloseDialog}
          formData={selectedCall}
          handleSave={handleSaveUpdatedData}
          handleUploadDialogOpen={handleUploadDialogOpen}
          selectCallType={selectCallType}
        />}
        <UploadPhoto
          open={uploadDialogOpen}
          handleClose={handleUploadDialogClose}
          labels={labels}
          handleFileChange={handleFileChange}
          handleUpload={handleUpload}
          removePreview={removePreview}
          filePreviews={filePreviews}
        />
        {part &&
        <ViewPartsList
        open={partsDilogOpen}
        handleClose={handlePartsDialogClose}
        part={part}
        />}
        {clicked === true &&<ViewImageList
        open={imgDilogOpen}
        handleClose={handleImagesDialogClose}
        images={images}
        />}
      </Box>
    </>
  );
};

export default CallListView;
