import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewListIcon from '@mui/icons-material/ViewList';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMadeIcon from '@mui/icons-material/CallMade';
import BuildIcon from '@mui/icons-material/Build';
import RestoreIcon from '@mui/icons-material/Restore';
import logoNew from '../../assets/images/logo-new.svg';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const Root = styled('div')`
  display: flex;
`;

const AppBarStyled = styled(AppBar)`
  z-index: ${(props) => props.theme.zIndex.drawer + 1};
`;

const DrawerStyled = styled(Drawer)`
  width: ${drawerWidth}px;
  flex-shrink: 0;
`;

const DrawerContent = styled('div')`
  margin-top: 64px; /* adjust this value based on your AppBar height */
  padding: 16px;
`;

const MenuItems = [
  { text: 'Dashboard', icon: <DashboardIcon sx={{ color: '#1acdee' }} />, path: '/dashboard' },
  { text: 'Call List View', icon: <ViewListIcon sx={{ color: '#1acdee' }} />, path: '/call-list-view' },
  { text: 'Registered Calls', icon: <CallReceivedIcon sx={{ color: '#1acdee' }} />, path: '/register-call-List' },
  { text: 'New Call Register', icon: <CallMadeIcon sx={{ color: '#1acdee' }} />, path: '/new-call-register' },
  { text: 'Refurbishment Calls', icon: <RestoreIcon sx={{ color: '#1acdee' }} />, path: '/refurbishment-calls' },
  { text: 'Preventive Maintenance Calls', icon: <BuildIcon sx={{ color: '#1acdee' }} />, path: '/PM-calls' },
];

const Header = () => {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleLogout = () => {
    navigate("/");
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    setIsDrawerOpen(false);
  };

  return (
    <Root>
      <AppBarStyled position="fixed" sx={{ backgroundColor: "#1acdee" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            <img src={logoNew} alt="Logo" style={{ maxWidth: '100px', verticalAlign: 'middle' }} />
          </Typography>
          <Typography variant="body1" sx={{ color: '#335bff', cursor: 'pointer' }} onClick={handleLogout}>
            Sign Out
          </Typography>
        </Toolbar>
      </AppBarStyled>
      <DrawerStyled
        variant="temporary"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <DrawerContent>
          <List>
            {MenuItems.map((item) => (
              <ListItem button key={item.text} onClick={() => handleMenuItemClick(item.path)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </DrawerContent>
      </DrawerStyled>
    </Root>
  );
}

export default Header;
