import axios from 'axios';

const baseURL = 'https://frigoserveindia.com/index.php/app';

const http = axios.create({
  baseURL,
});

const request = async (method, endpoint, data, headers) => {
  try {
    const response = await http({
      method,
      url: endpoint,
      data,
      headers,
    });
    return response.data;
  } 
  catch (error) {
    throw error.response.data;
  }
};

export default {
  get: async (endpoint, headers) => request('get', endpoint, null, headers),
  post: async (endpoint, data, headers) => request('post', endpoint, data, headers),
  put: async (endpoint, data, headers) => request('put', endpoint, data, headers),
  delete: async (endpoint, headers) => request('delete', endpoint, null, headers),
};