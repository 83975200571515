import React, { useState, useEffect, memo } from 'react';
import { Card, CardContent, Grid, Typography, Button, Box, Stack, Divider, CircularProgress, Alert } from '@mui/material';
import ViewLocation from './ViewLocation';
import { fetchCity, fetchCityByCityId, fetchState } from '../../utils/CommonFunction';
//import UpdateRegistation from './UpdateRegistration';

const RegisterCallListGrid = ({
    fetchedData,
    subtitles,
    dataKeys,
    handleUpdate
}) => {
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedCall, setSelectedCall] = useState({});
    const [locationData, setLocationData] = useState({ state: '', city: '', outletName: '' });

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleString('en-US', options);
    };

    const fetchStateData = async () => {
        const fetchedStates = await fetchState();
        if (fetchedStates) {
            setStates(fetchedStates);
        }
    };

    const fetchCityData = async () => {
            const cityData = new FormData();
            cityData.append("cityID", selectedCall.city);
            const fetchedCities = await fetchCityByCityId(cityData);
            if (fetchedCities) {
                setCities(fetchedCities);
            }
    };

    useEffect(() => {
        fetchStateData();
    }, []);

    useEffect(() => {
        if (selectedCall) {
            fetchCityData();
        }
    }, [selectedCall.city]);

    useEffect(() => {
        if (selectedCall.state_id && cities.length > 0) {
            const stateName = getStateNameById(selectedCall.state_id);
            const cityName = getCityNameById(selectedCall.city);
            setLocationData({
                state: stateName,
                city: cityName,
                outletName: selectedCall.address_1
            });
        }
    }, [cities, selectedCall]);

    const getStateNameById = (state_id) => {
        const state = states.find(state => state.StateID === state_id);
        return state ? state.StateName : null;
    };

    const getCityNameById = (City_id) => {
        const city = cities.find(city => city.id === City_id);
        return city ? city.description : null;
    };

    const handleViewLocation = (data) => {
        setSelectedCall(data);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" mt={5} height="200px">
                <CircularProgress />
            </Box>
        );
    }

    if (alertMessage) {
        return (
            <Alert severity="warning">{alertMessage}</Alert>
        );
    }

    if (fetchedData.length === 0) {
        return (
            <Typography variant="h6" align="center" gutterBottom>
                No Records Found
            </Typography>
        );
    }

    return (
        <>
            {fetchedData.map((data, index) => (
                <Box mb={2} key={index} >
                    <Card sx={{ backgroundColor: "#feffea" }}>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        sx={{
                                            backgroundColor: '#49796B',
                                            color: 'white',
                                            fontWeight: 'bold',
                                            padding: '8px',
                                            borderRadius: '20px'
                                        }}>
                                        {formatDate(data.created_on_date)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction={'row'} alignItems="left" sx={{ justifyContent: 'space-between', px: '8px' }}>
                                        <Typography variant="body" gutterBottom color="primary" sx={{ fontWeight: 'bold' }}>
                                            Complaint No:
                                        </Typography>
                                        <Typography variant="body1" gutterBottom color="primary">
                                            {data.complaint_no}
                                        </Typography>
                                    </Stack>
                                    <Divider />
                                </Grid>
                                {subtitles.map((subtitle, i) => (
                                    <Grid item xs={12} key={i}>
                                        <Stack direction={'row'} alignItems="left" sx={{ justifyContent: 'space-between', px: '8px' }}>
                                            <Typography variant="body" gutterBottom color="primary" sx={{ fontWeight: 'bold' }}>
                                                {subtitle}
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                {data[dataKeys[i]]}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                ))}
                                <Grid item xs={6}>
                                    <Button variant="contained" color="primary" onClick={() => handleViewLocation(data)}>
                                        View Location
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    {(data.call_status !== 'Completed' && data.call_status !== 'Technical Closer') && (
                                        <Button variant="contained" color="secondary" onClick={() => handleUpdate(data)}>
                                            Update Call
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            ))}
            {/* <UpdateRegistation
                open={dialogOpen}
                handleClose={() => setDialogOpen(false)}
                formData={selectedCall}
                handleFormChange={handleFormChange}
                handleSave={handleSave}
            /> */}
           {locationData &&
            <ViewLocation
                open={dialogOpen}
                onClose={handleCloseDialog}
                apiKey="AIzaSyDhRQaWrORz1dr4-nWgDA7f74qwx_5gHBk"
                state={locationData.state}
                city={locationData.city}
                outletName={locationData.outletName}
            />
           }
        </>
    );
};

export default memo(RegisterCallListGrid);
