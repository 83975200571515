import React from 'react'
import {
	BrowserRouter,
	Routes,
	Route,
} from "react-router-dom";
import HomePage from '../../pages/HomePage/HomePage';
import DashboardPage from '../../pages/dashboardPage/DashboardPage';
import RegisteredCallListPage from '../../pages/registeredCallListPage/RegisteredCallListPage';
import NewCallRegisterPage from '../../pages/newCallRegisterPage/NewCallRegisterPage';
import PMCallsPage from '../../pages/pmCallsPage/PMCallsPage';
import CallListViewPage from '../../pages/callListViewPage/CallListViewPage';
import RefurbishmentCallsPage from '../../pages/refurbishmentCallsPage/RefurbishmentCallsPage';


const AppRoute = () => {
  return (
    <BrowserRouter>
    <Routes>
				<Route path="/" element={<HomePage/>} />
				<Route path="/dashboard" element={<DashboardPage/>} />
				<Route path="/call-list-view" element={<CallListViewPage/>} />
				<Route path="/register-call-List" element={<RegisteredCallListPage/>} />
				<Route path="/new-call-register" element={<NewCallRegisterPage/>} />
				<Route path="/refurbishment-calls" element={<RefurbishmentCallsPage/>} />
				<Route path="/PM-calls" element={<PMCallsPage/>} />
			</Routes>
    </BrowserRouter>
  )
}

export default AppRoute
